import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../static/Dashboard.css';
import '../static/Secondchatbox.css';
import { useAuth } from '../AuthContext';
import ChatBox from '../components/ChatBox';
import AskBox from '../components/AskBox';
import AttachChatBox from '../components/attachChatBot';
import JustChatBox from '../components/Justchatbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paperPlane from '../static/images/paper-plane.png'; // Import the image

import { faBars,faPaperPlane,faUser, faPlus,faFileUpload,faPaperclip ,faHome, faExclamationTriangle, faDatabase, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Disclaimer from '../components/Disclaimer';

const DisclaimerPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Disclaimer - ChatNFD';
    }, []);

    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };



    useEffect(() => {
        const $button = document.querySelector('#sidebar-toggle');
        const $wrapper = document.querySelector('#wrapper');

        const handleToggle = (e) => {
            e.preventDefault();
            $wrapper.classList.toggle('toggled');
        };

        $button.addEventListener('click', handleToggle);

        // Cleanup function to remove the event listener
        return () => {
            $button.removeEventListener('click', handleToggle);
        };
    }, []);

    return (
        <div className="dashboard-container">
            {/* Navigation Bar */}
            <div id="wrapper">

<aside id="sidebar-wrapper">
  <div class="sidebar-brand">
  <a href="/"><h2>askNature.ai</h2></a>
  </div>
  <ul class="sidebar-nav">
    <li>
      <a href="/"> <FontAwesomeIcon icon={faHome} className='leftmargin'/> Home</a>
    </li>
    <li class="active">
      <a href="/disclaimer"> <FontAwesomeIcon icon={faExclamationTriangle} className='leftmargin'/> Disclaimer</a>
    </li>
    <li >
      <a href="/database"> <FontAwesomeIcon icon={faDatabase} className='leftmargin'/> Database</a>
    </li>
    <li>
    <Link onClick={handleLogout} > <FontAwesomeIcon icon={faUser} className='leftmargin' /> Logout</Link>

    </li>
  </ul>
</aside>

<div id="navbar-wrapper">
  <nav class="navbar navbar-inverse">
    <div class="container-fluid">
      <div class="navbar-header ">
        <a href="#" class="navbar-brand" id="sidebar-toggle"><span className="navbar-toggler-icon"></span></a>
      </div>
      
    </div>
  </nav>
</div>


                    {/* Main Content */}
                    <main className="col-md-10 ms-sm-auto col-lg-12 px-md-4 ">

                    < Disclaimer />

                    </main>
                    </div>
        </div>
    );
};

export default DisclaimerPage;
