import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../static/Dashboard.css';
import '../static/Secondchatbox.css';
import { useAuth } from '../AuthContext';
import ChatBox from '../components/ChatBox';
import AskBox from '../components/AskBox';
import AttachChatBox from '../components/attachChatBot';
import JustChatBox from '../components/Justchatbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paperPlane from '../static/images/paper-plane.png'; // Import the image

import { faBars, faPaperPlane, faUser, faPlus, faFileUpload, faPaperclip, faHome, faExclamationTriangle, faDatabase, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Database from '../components/Database';

const DatabasePage = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [activeTab, setActiveTab] = useState("database"); // State to manage active tab

    useEffect(() => {
        document.title = 'Database - ChatNFD';
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    useEffect(() => {
        const $button = document.querySelector('#sidebar-toggle');
        const $wrapper = document.querySelector('#wrapper');

        const handleToggle = (e) => {
            e.preventDefault();
            $wrapper.classList.toggle('toggled');
        };

        $button.addEventListener('click', handleToggle);

        // Cleanup function to remove the event listener
        return () => {
            $button.removeEventListener('click', handleToggle);
        };
    }, []);

    return (
        <div className="dashboard-container">
            {/* Navigation Bar */}
            <div id="wrapper">
                <aside id="sidebar-wrapper">
                    <div className="sidebar-brand">
                        <a href="/"><h2>askNature</h2></a>
                    </div>
                    <ul className="sidebar-nav">
                        <li>
                            <a href="/"> <FontAwesomeIcon icon={faHome} className='leftmargin'/> Home</a>
                        </li>
                        <li>
                            <a href="/disclaimer"> <FontAwesomeIcon icon={faExclamationTriangle} className='leftmargin'/> Disclaimer</a>
                        </li>
                        <li className="active">
                            <a href="/database"> <FontAwesomeIcon icon={faDatabase} className='leftmargin'/> Database</a>
                        </li>
                        <li>
                            <Link onClick={handleLogout}> <FontAwesomeIcon icon={faUser} className='leftmargin' /> Logout</Link>
                        </li>
                    </ul>
                </aside>

                <div id="navbar-wrapper">
                    <nav className="navbar navbar-inverse">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <a href="#" className="navbar-brand" id="sidebar-toggle"><span className="navbar-toggler-icon"></span></a>
                            </div>
                        </div>
                    </nav>
                </div>

                {/* Main Content with Tabs */}
                <main className="col-md-10 ms-sm-auto col-lg-12 px-md-4">
                    {/* Tab Navigation */}
                    <div className="tab-container">

                    <button 
                            className={`tab-button ${activeTab === "regulation" ? "active" : ""}`} 
                            onClick={() => setActiveTab("regulation")}
                        >
                          Regulation Database
                        </button>
                        <button 
                            className={`tab-button ${activeTab === "database" ? "active" : ""}`} 
                            onClick={() => setActiveTab("database")}
                        >
                            User Database
                        </button>
                       
                    </div>

                    {/* Tab Content */}
                    <div className="tab-content">
                    {activeTab === "regulation" && 
                     <div className="card">
                     <div className="card-body">
                       <h1 className="card-title">TNFD Recommendations and Resources</h1>
                       <p className="card-text">
                         Here are some key resources related to the Taskforce on Nature-related Financial Disclosures (TNFD) recommendations:
                       </p>
                    <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Report</th>
                  <th>Number of pages</th>
                  <th>Release date</th>
                </tr>
              </thead>
              <tbody>
  <tr>
    <td><a href="https://tnfd.global/publication/recommendations-of-the-taskforce-on-nature-related-financial-disclosures/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/08/Recommendations_of_the_Taskforce_on_Nature-related_Financial_Disclosures_September_2023.pdf?v=1695118661">Taskforce on Nature-related Financial Disclosures (TNFD) Recommendations</a></td>
    <td>154</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/executive-summary-2">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/09/Executive_summary_of_the_TNFD_recommendations.pdf?v=1695117009">Executive Summary of the TNFD Recommendations</a></td>
    <td>7</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-guidance-on-assessment-of-nature-related-issues-the-leap-approach">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/08/Guidance_on_the_identification_and_assessment_of_nature-related_Issues_The_TNFD_LEAP_approach_V1.1_October2023.pdf?v=1698403116">Guidance on the identification and assessment of nature-related issues: the LEAP approach</a></td>
    <td>277</td>
    <td>2023</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/guidance-on-scenario-analysis">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/09/Guidance_on_scenario_analysis_V1.pdf?v=1695138235">Guidance on scenario analysis</a></td>
    <td>52</td>
    <td>2023</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/guidance-on-engagement-with-indigenous-peoples-local-communities-and-affected-stakeholders">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/08/Guidance_on_engagement_with_Indigenous_Peoples_Local_Communities_and_affected_stakeholders_v1.pdf?v=1695138220">Guidance on engagement with Indigenous Peoples, Local Communities and affected stakeholders</a></td>
    <td>56</td>
    <td>2023</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-draft-guidance-for-corporates-on-science-based-targets-for-nature-2">TNFD-SBTN</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/09/Guidance_for_corporates_on_science_based_targets_for_nature_v1.pdf?v=1695138398">Guidance for corporates on science-based targets for nature</a></td>
    <td>18</td>
    <td>2023</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/glossary">TNFD-Glossary</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/09/Glossary_of_key_terms_v1.pdf?v=1702506695">Glossary</a></td>
    <td>72</td>
    <td>2023</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/getting-started-with-adoption-of-the-tnfd-recommendations">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2023/09/Getting_started_TNFD_v1.pdf?v=1698156380">Getting started with adoption of the TNFD Recommendations</a></td>
    <td>22</td>
    <td>2023</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-metals-and-mining/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Metals-and-mining.pdf?v=1719526916">Additional sector guidance – Metals and mining</a></td>
    <td>94</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-electric-utilities-and-power-generators/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Electric-Utilities-and-Power.pdf?v=1719525999">Additional sector guidance – Electric utilities and power generators</a></td>
    <td>74</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-disclosure-guidance-for-financial-institutions/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/TNFD-Additional-guidance-for-financial-Institutions_v2.0.pdf?v=1720185908">Additional guidance for financial institutions</a></td>
    <td>37</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-chemicals/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Chemicals.pdf?v=1719525648">Additional sector guidance – Chemicals</a></td>
    <td>61</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-food-and-agriculture/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Food-and-Agri.pdf?v=1719526279">Additional sector guidance – Food and agriculture</a></td>
    <td>86</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-oil-and-gas/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Oil-and-gas.pdf?v=1719527044">Additional sector guidance – Oil and gas</a></td>
    <td>72</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-forestry-pulp-and-paper/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Forestry-and-paper.pdf?v=1719526698">Additional sector guidance – Forestry, pulp and paper</a></td>
    <td>68</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-aquaculture/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Aquaculture.pdf?v=1719525260">Additional sector guidance – Aquaculture</a></td>
    <td>79</td>
    <td>2024</td>
  </tr>
  <tr>
    <td><a href="https://tnfd.global/publication/additional-sector-guidance-biotechnology-and-pharmaceuticals/#publication-content">TNFD</a></td>
    <td><a href="https://tnfd.global/wp-content/uploads/2024/06/Additional-Sector-Guidance-Biotech-and-Pharma.pdf?v=1719525490">Additional sector guidance – Biotechnology and pharmaceuticals</a></td>
    <td>63</td>
    <td>2024</td>
  </tr>
</tbody>
              </table>
              </div>
              </div>
              </div>
              }

                        {activeTab === "database" && <Database />}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DatabasePage;
